/** @jsx jsx */
import ProductPage from '@bottlebooks/gatsby-theme-event/src/components/EventProductPage/EventProductPage';
import { graphql } from 'gatsby';
import deepMerge from 'lodash/merge';
import { jsx } from 'theme-ui';
import getTopDropPricing from '../getTopDropPricing';

export default function ProductTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const {
    event,
    product,
    producer,
    exhibitor,
    otherProducts,
    next,
    previous,
    siteTitle,
  } = afterQuery(data);
  return (
    <ProductPage
      {...pageContext}
      event={event}
      product={product}
      exhibitor={exhibitor}
      otherProducts={otherProducts}
      otherProductsName={producer?.name}
      previous={previous}
      next={next}
      siteTitle={siteTitle}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  query topDrop_ProductTemplate(
    $id: String!
    $productId: ID!
    $eventId: ID!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        eventId
        # Get the IDs and other data out of registeredProducts.
        registeredProducts(
          filter: {
            productId: { eq: $productId }
            partnerId: { eq: $exhibitorId }
          }
        ) {
          nodes {
            ...EventProductPage_RegisteredProduct
            ...topDrop_Price
          }
        }
        registrations(filter: { companyId: { eq: $exhibitorId } }) {
          nodes {
            # This is a quick fix to get the data onto the page
            ...bb_Registration
          }
        }
        product(productId: $productId, exhibitorId: $exhibitorId) {
          ...bb_EventProductPage
          ... on Bottlebooks_Wine {
            wineType(format: LOCALIZED)
          }
        }
      }
    }
    product(id: { eq: $id }) {
      id
      ...EventProductPage
      producer {
        name
        products {
          ...EventProductPage_Related
        }
      }
    }
    previous: product(id: { eq: $previousId }) {
      ...ChangeProductLink
    }
    next: product(id: { eq: $nextId }) {
      ...ChangeProductLink
    }
    site {
      siteMetadata {
        title
        topDropCity
        topDropPriceType
      }
    }
  }
`;

function afterQuery({
  bottlebooks,
  product,
  previous,
  next,
  site: { siteMetadata },
}) {
  const { topDropPriceType, topDropCity } = siteMetadata;
  console.log(`Pricing for ${topDropCity} ${topDropPriceType}`);
  const event = bottlebooks?.event;
  // Pick out the registeredProduct data, so we can mix it into the base product.
  const registeredProduct = bottlebooks?.event?.registeredProducts?.nodes?.[0];
  const exhibitor = bottlebooks?.event?.registrations?.nodes?.[0];
  const extendedProduct = deepMerge(
    {},
    { ...product, exhibitor: { ...product?.exhibitor, ...exhibitor } },
    bottlebooks?.event?.product,
    registeredProduct?.product,
    registeredProduct,
    {
      pricing: {
        isSoldByEverythingWine: !!registeredProduct?.everythingWine?.text,
        topDropPriceType,
        ...getTopDropPricing({
          topDropPriceType,
          topDropCity,
          registeredProduct,
        }),
      },
    }
  );
  const producerProducts = extendedProduct.producer?.products || [];
  const otherProducts = producerProducts
    .filter((other) => other.id !== product.id)
    .slice(0, 6);

  return {
    product: extendedProduct,
    producer: extendedProduct?.producer,
    exhibitor,
    event,
    siteTitle: siteMetadata.title,
    next,
    previous,
    otherProducts,
  };
}
